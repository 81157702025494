import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { connect } from 'react-redux';

function StoryDialog(props) {
    const { open, onHide, actionData, onAction, profile } = props
    const defaultValues = {
        adminComment: '',
    }
    
    const onSubmit = (data) => {
        onAction({ 
            ...actionData,
            ...data,
            id:actionData?._id,
            updatedBy:profile?.id
         })
    }
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
    useEffect(() => {
        reset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    return (
        <Dialog header={(actionData?.header)?actionData?.header+" Complaints":"Complaints"} visible={open} onHide={() => onHide()} breakpoints={{ '960px': '75vw' }} draggable={false} focusOnShow={false} closeOnEscape={false}
            style={{ width: '50vw' }} >
            <div>
                <h6>Reported reason by user</h6>
                <InputText minLength={2} maxLength={50} required readOnly
                    style={{ width: "100%" }} placeholder="Reason" value={actionData?.comment} />
            </div>
            <br />
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                <div className="p-field">
                    <br/>
                    <span className="p-float-label">
                        <Controller name="adminComment" control={control} rules={{ required: 'Reason is required.' }} render={({ field, fieldState }) => (
                            <InputText id={field.name} {...field} minLength="5" autoComplete="off"  results={0} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        <label htmlFor="adminComment" className={classNames({ 'p-error': errors.name })}>Reason by admin*</label>
                    </span>
                    {getFormErrorMessage('adminComment')}
                </div>
                <div style={{ display: "flex", margin: "2rem 0 0 4rem", justifyContent: "end" }}>
                    {actionData?.status === "REJECTED" && <Button style={{ width: "15%" }} type="submit" label="Reject" />}
                    {actionData?.status === "APPROVED" && <Button style={{ width: "15%" }} type="submit" label="Block" className="p-button-danger" />}
                    <Button style={{ width: "15%" }} label="Close" onClick={() => onHide()} className="p-button-text" />
                </div>
            </form>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
      profile: state.profile,
    }
  }
  
export default connect(mapStateToProps)(StoryDialog)