import axios from 'axios'
import { config } from '../utils/urls'

/**
 * @description Destructure the url and adding complaint path
 */
const url = `${config.url.JURNI_API_URL}/user/switch`

export const getAllCoachRequests = async () => {
    try {
        const response = await axios.get(`${url}/findAll`)
        const { data } = response
        return data
    } catch (response) {
        return response
    }
}
export const updateCoachRequests = async (requestData) => {
    try {
        const response = await axios.post(`${url}/update`,requestData)
        const { data } = response
        return data
    } catch (response) {
        return response
    }
}