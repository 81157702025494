import axios from 'axios'

import { config } from '../utils/urls'

const url = `${config.url.JURNI_API_URL}/transaction`
export const getAllTransactions = async () => {
    try {
        const response = await axios.get(`${url}/all`)
        return response?.data
    } catch (error) {
        return {
            success: false
        }
    }
}