import React, { useEffect } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import routes from '../../routes';
import componentStyles from "../../assets/theme/layouts/admin.js";
import Sidebar from '../../components/Sidebar/Sidebar.js'
import TopToolbar from '../../components/Navbars/TopToolbar';
import CoachPayouts from "../../screens/Transactions/Payouts/coachPayouts";
export default function Layout(props) {
    const useStyles = makeStyles(componentStyles);
    const classes = useStyles();
    const location = useLocation();

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            return (
                <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                    exact
                />
            );
        });
    };
    const getBrandText = () => {
        console.log("-----",props)
        const brandText = location.pathname.split("/");
        console.log(brandText.length)
        if(brandText.length !== 4) {
            return brandText[brandText.length - 1];
        } else {
            return "Payouts";
        }
        
    };
    useEffect(() => {

    }, [])
    return (
        <div>
            <Sidebar
                routes={routes}
                logo={{
                    innerLink: "/dashboard",
                    imgSrc: "https://myjurni.org/wp-content/uploads/2021/06/Finaled-logo-3.jpg",
                    imgAlt: "...",
                }}
            />
            <Box position="relative" className={classes.mainContent}>
                <TopToolbar pageName={getBrandText()} />
                <Switch>
                    {getRoutes(routes)}
                    <Route path={"/transactions/payouts/:id"} component={CoachPayouts} />
                    <Redirect from="*" to="/dashboard" />
                </Switch>
                <Container
                    maxWidth={false}
                    component={Box}
                    classes={{ root: classes.containerRoot }}
                >
                </Container>
            </Box>
        </div>
    )
}