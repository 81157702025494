import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {
    Avatar,
    Tooltip
} from '@material-ui/core';
import { InputText } from 'primereact/inputtext';
import { Paper } from '@material-ui/core';
import { Col, Row } from 'react-bootstrap';
// import { InputText } from 'primereact/inputtext';
//import MaterialTable from 'material-table';
import './table.css'
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
export const PayoutTable = (props) => {
    const { data, columns, onFilter, onClearFilter, type } = props
    const [globalFilter, setGlobalFilter] = useState("")
    const [expandedRows, setExpandedRows] = useState(null);
    const [dateRange, setDateRange] = useState([]);

    const header = (
        <div className="table-header" style={{ display: "flex", justifyContent: "flex-end" }}>
            {onFilter && <Calendar id="range" value={dateRange} placeholder={"Filter by date"} onChange={(e) => {
                setDateRange(e.value)
                if (e.value[1]) {
                    onFilter(e.value)
                }
            }} selectionMode="range" readOnlyInput showIcon icon="pi pi-filter" />} &nbsp;&nbsp;&nbsp;

            {dateRange[1] && <span style={{ display: "flex", alignItems: "center" }}>
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined" style={{ color: "red", cursor: "pointer" }} onClick={() => {
                    setDateRange([])
                    onClearFilter()
                }} />
            </span>}
            &nbsp;&nbsp;&nbsp;
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
            </span>
        </div>
    );
    const onRowExpand = (event) => {
        //toast.current.show({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
    }
    const rowExpansionTemplateCoachRequest = (data) => {
        const { about, status, rejectReason, category } = data
        const { email, name, dp } = data?.user
        return (
            <div style={{ padding: "30px", backgroundColor: "#fafafa" }}>
                <Paper elevation={1} >
                    <Row>
                        <Col xs="12" key="left" lg="4" md="4" xl="3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {dp ? <img style={{ height: "auto", width: "100%", }} src={dp}
                                alt="profile" />
                                :
                                <img alt="Profile" style={{ height: "auto", width: "60%", margin: "20px 0" }} src={"https://user-images.githubusercontent.com/48409160/134347322-66719ba0-8ec7-49de-8eab-d4acbca294cc.jpeg"} />
                            }
                        </Col>
                        <Col xs="12" key="right" lg="8" md="8" xl="9" style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", padding: "1rem" }}>
                            <div>
                                <br />
                                <p style={{ fontWeight: 500, fontSize: 19 }}>{about}</p> <br />

                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignContent: "end" }}>
                                {name}<br />
                                {email} <br /><br />
                                Category : {category ? category : "N/A"}
                                {status === "REJECTED" && <div style={{ display: "flex" }}>
                                    <p style={{ fontWeight: 600 }}>Reason for reject:</p> &nbsp;
                                    <p>{rejectReason}</p>
                                </div>}
                                {/* {data?.specialization} */}
                            </div>
                        </Col>
                    </Row>
                </Paper>
            </div>
        );
    }
    const coachesExpansionTemplate = (data) => {
        const { dp, name, email, coachData } = data
        const { category, about } = coachData
        return (
            <div style={{ padding: "30px", backgroundColor: "#fafafa" }}>
                <Paper elevation={1} >
                    <Row>
                        <Col key="left1" xs="12" lg="4" md="4" xl="3" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            {dp ? <img style={{ height: "auto", width: "100%", }} src={dp}
                                alt="profile" />
                                :
                                <img alt="Profile" style={{ height: "auto", width: "60%", margin: "20px 0" }} src={"https://user-images.githubusercontent.com/48409160/134347322-66719ba0-8ec7-49de-8eab-d4acbca294cc.jpeg"} />
                            }
                        </Col>
                        <Col key="right1" xs="12" lg="8" md="8" xl="9" style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", padding: "1rem" }}>
                            <div>
                                <br />
                                <p style={{ fontWeight: 500, fontSize: 19 }}>{about}</p>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignContent: "end" }}>
                                {name}<br />
                                {email} <br /><br />
                                {category}
                            </div>
                        </Col>
                    </Row>
                </Paper>
            </div>
        )
    }
    const headerTemplate = (data) => {
        return (
            <React.Fragment >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", alignContent: "center" }}>
                        {data?.bookingId?.coach?.dp ? <Avatar src={data?.bookingId?.coach?.dp} className="p-mr-2" size="xlarge" shape="circle" /> :
                            <Avatar icon="pi pi-user" className="p-mr-2" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} shape="circle" />}
                    </div>
                    <Tooltip title={data?.bookingId.coach.name} >
                        <p style={{ margin: " 0 0 0 .65rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", }}>
                            {data?.bookingId.coach.name}
                        </p>
                    </Tooltip>
                </div>
            </React.Fragment>
        );
    }
    const onRowCollapse = (event) => {
        setExpandedRows("")
        //toast.current.show({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
    }
    const footerTemplate = (data) => {
        return (
            <React.Fragment>

            </React.Fragment>
        );
    }
    return (
        <div className="datatable-responsive-demo" >
            {/* <MaterialTable actions={actions} data={data} columns={columns}
                title={title} editable={editable} options={{
                    sorting: true, defaultSort: "asc"
                    , ...options
                }} style={style} detailPanel={detailPanel}
            /> */}
            <DataTable value={data}
                header={header}
                //rowGroupMode={"subheader"}
                editMode="row"
                removableSort
                className="p-datatable-responsive-demo "
                sortMode="single"
                groupField={"bookingId.coach.name"}
                rowGroupHeaderTemplate={(data) => headerTemplate(data)}
                rowGroupFooterTemplate={(data) => footerTemplate(data)}
                paginator
                style={{ fontSize: "0.9rem" }}
                rows={10}
                dataKey="_id"
                expandedRows={expandedRows}
                globalFilter={globalFilter}
                onRowToggle={(e) => {
                    setExpandedRows(e.data)
                }}
                onRowExpand={onRowExpand} rowExpansionTemplate={type === "coaches" ? coachesExpansionTemplate : rowExpansionTemplateCoachRequest}
                onRowCollapse={onRowCollapse}
            >
                {columns && columns.map((column) => {
                    return (
                        <Column style={{
                            ...column.style,
                            textAlign: "center",
                            fontWeight: 500
                        }
                        }
                            key={column._id}
                            field={column.field} header={column.title} headerStyle={{ fontSize: "28px !important", textAlign: "center", fontWeight: 600, backgroundColor: "#E6EAED" }}
                            body={column.render ? column.render : false}
                            sortable={column.sort ? column.sort : false}
                        />
                    )
                })}

                {/* {isAdmin() && (deleteAction || edit) && <Column header="Actions" body={(e) => customAction(e)}
                    style={{ width: "8%" }}
                ></Column>} */}
            </DataTable>

        </div>

    )
}
export default PayoutTable