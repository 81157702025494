import Layout from "./screens/Layout/Layout";
import { connect } from 'react-redux';
import Login from './screens/auth/login';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Redirect } from 'react-router-dom'
import NoInternetDialog from './components/Dialogs/NoInternet/noInternet.js'
import { useState } from "react";
function App({ profile }) {
  const [offline, setOffline] = useState(false)
  window.addEventListener('offline', function (e) {
    setOffline(true)
  });
  window.addEventListener('online', function (e) {
    setOffline(false)
  });
  const isLoggedIn = () => {
    if (profile?.token) {
      return true
    } else {
      return false
    }
  }
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />
          {isLoggedIn() ?
            <Layout /> :
            <Redirect from="*" to="/login" />}
        </Switch>
      </BrowserRouter>
      <NoInternetDialog open={offline} />

    </div>
  );
}

const mapStateToProps = state => {
  return {
    profile: state.profile,
  }
}
export default connect(mapStateToProps, null)(App);
