import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { connect } from 'react-redux';

function CategoryAdd(props) {
    const { open, onHide, profile, category, onSave, onEdit } = props
    const [name, setName] = useState("")
    const [isEdit, setIsEdit] = useState(false)

    const onSubmit = (e) => {
        e.preventDefault()
        if (isEdit) {
            onEdit({ name: name, updatedBy: profile?.id, id: category?._id })
        } else {
            onSave({ name: name, createdBy: profile?.id })
        }
    }

    useEffect(() => {
        if (category && category?.name) {
            setName(category?.name)
            setIsEdit(true)
        } else {
            setName("")
            setIsEdit(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    return (
        <Dialog header={"Add Category"} visible={open} onHide={() => onHide()} breakpoints={{ '960px': '75vw' }} draggable={false} focusOnShow={false} closeOnEscape={false}
            style={{ width: '50vw' }} >
            <form onSubmit={onSubmit} className="p-fluid">
                <br />
                <span className="p-float-label">
                    <InputText required minLength={3} value={name} onChange={(e) => { setName(e.target.value) }} />
                    <label >Category Name</label>
                </span>
                <div style={{ display: "flex", margin: "2rem 0 0 4rem", justifyContent: "end" }}>
                    <Button style={{ width: "15%" }} type="button" label="Close" onClick={() => onHide()} className="p-button-text" /> &nbsp;
                    {isEdit && <Button style={{ width: "15%" }} type="submit" label="Update" />}
                    {!isEdit && <Button style={{ width: "15%" }} type="submit" label="Add" />}
                </div>
            </form>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
        profile: state.profile,
    }
}

export default connect(mapStateToProps)(CategoryAdd)