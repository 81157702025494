import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { Chip } from 'primereact/chip';
import { useParams } from 'react-router-dom';

// Components
import RequestTable from "../../../components/Table/RequestTable";
import Loader from '../../../components/Loader/Loader';
import { AlertSnackbar } from '../../../components/Toasts/toasts';
import ToolbarTab from '../../../components/Toolbar/Toolbar';
import IconButton from '../../../components/Buttons/IconButton'

// Services
import { getAllCoachPayouts } from '../../../services/payoutService'

// Styles
import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "../../../assets/theme/components/page-header.js";

// Utils
import { filterByStatus, initialFilterByStatus } from '../../../utils/FilterPayouts'

// Icons 
import { FaCcPaypal } from "react-icons/fa";
import { FaCcStripe } from "react-icons/fa";
import PayoutDialog from "../../../components/Dialogs/CoachPayout";

//Constants
import { settlementPercentage } from '../../../constants'

const useStyles = makeStyles(componentStyles);
export default function CoachPayouts(props) {
    const { id } = useParams()
    const [payouts, setPayouts] = useState([])
    const [copyPayouts, setCopyPayouts] = useState([])
    const [coachName, setCoachName] = useState("")
    const [pendingPayouts, setPendingPayouts] = useState([])
    const [pendingAmount, setPendingAmount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [payDialogOpen, setPayDialogOpen] = useState(false)
    const [openToast, setOpenToast] = useState(false)
    const [toastData, setToastData] = useState({
        message: "",
        variant: ""
    })
    const [filter, setFilter] = useState("PENDING")
    const classes = useStyles()
    const fetchPayouts = async (id) => {
        setIsLoading(true)
        const response = await getAllCoachPayouts(id)
        const { success, data } = response
        if (success === true) {
            setPayouts(data)
            setCopyPayouts(data)
            getInitialData(data)
            setCoachName(props?.location?.state)
        } else {
            setOpenToast(true)
            setToastData({
                message: "Cannot fetch payouts.",
                variant: "error"
            })
        }
        setIsLoading(false)
    }
    const columns = [
        { title: "Coach Name", field: "bookingId.coach.name" },
        { title: "Amount", field: "bookingId.totalAmount", },
        {
            title: "Settlement",
            render: (rowData) => {
                return (
                    <span>
                        {rowData?.bookingId?.totalAmount * (settlementPercentage / 100)}
                    </span>
                )
            }
        },
        { title: "Currency", field: "transactionId.currency" },
        {
            title: "Payment", field: "status", render: (rowData) => {

                if (rowData?.status === "SUCCESS") {
                    return (<p style={{
                        padding: "2px", margin: "0 1rem",
                        background: "#b2fa8e", borderRadius: "10px", color: "#267300"
                    }} >
                        Success
                    </p>)
                } 
                else if(rowData?.status === "UNCLAIMED") {
                    return (<p style={{
                        padding: "2px", margin: "0 1rem",
                        background: "#f7a199", borderRadius: "10px", color: "#9e1306"
                    }} >
                        {rowData?.status}
                    </p>)
                }
                else {
                    return (<p style={{
                        padding: "2px", margin: "0 1rem",
                        background: "#fff3c9", borderRadius: "10px", color: "#d68b00"
                    }} >
                        {rowData?.status}
                    </p>)
                }
            }
        },
        {
            title: "Gateway", field: "transactionId.gateway",
            render: (rowData) => {
                if (rowData?.transactionId?.gateway === "STRIPE") {
                    return (
                        <FaCcStripe size={35} />
                    )
                } else if (rowData?.transactionId?.gateway === "PAYPAL") {
                    return (
                        <FaCcPaypal size={35} />
                    )
                }
            }
        },
        {
            title: "Billed Date",
            field: "createdAt",
            sort: true,
            render: (rowData) => {
                return (
                    <div>
                        <p style={{ margin: "0px" }}>{dateFormat(rowData.createdAt, "mmm dS, yyyy ")}
                            <p style={{ color: "#999999", margin: "0px" }}>
                                {dateFormat(rowData.createdAt, "shortTime")}
                            </p>
                        </p>
                    </div>
                );
            },
        },
    ]
    const filterHandlerType = (status) => {
        if (status === "All") {
            setFilter("All")
            setCopyPayouts(payouts)
        } else {
            setFilter(status)
            const response = filterByStatus(status, payouts)
            setCopyPayouts(response)
        }
    }
    const getInitialData = (data) => {
        const response = initialFilterByStatus("PENDING", data)
        setCopyPayouts(response?.filterData)
        setPendingPayouts(response?.filterData)
        setPendingAmount(response?.amount)
    }
    const leftTabContents = (
        <React.Fragment >
            <div onClick={() => {
                filterHandlerType("All")
            }}>
                <Chip label="All" style={(filter === "All") ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className={"toolbarButton"} />
            </div>
            <div onClick={() => {
                filterHandlerType("PENDING")
            }}>
                <Chip label="Pending" style={(filter === "PENDING") ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className="toolbarButton" />
            </div>
            <div onClick={() => {
                filterHandlerType("UNCLAIMED")
            }}>
                <Chip label="Unclaimed" style={(filter === "UNCLAIMED") ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className="toolbarButton" />
            </div>
            <div onClick={() => {
                filterHandlerType("SUCCESS")
            }}><Chip label="Success" style={(filter === "SUCCESS") ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className="toolbarButton" />
            </div>
        </React.Fragment>
    )
    const rightTabContents = (
        <React.Fragment >
            <IconButton className="p-button-raised" label={"Pay"} onClick={() => setPayDialogOpen(true)} />
        </React.Fragment>
    )

    useEffect(() => {
        id && fetchPayouts(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    return (
        <div>
            <div className={classes.header} />
            <ToolbarTab leftContents={leftTabContents} rightContents={rightTabContents} />
            <RequestTable data={copyPayouts} columns={columns} />
            <Loader open={isLoading} />
            <AlertSnackbar open={openToast} handleClose={() => setOpenToast(false)} variant={toastData.variant} message={toastData.message} />
            <PayoutDialog open={payDialogOpen} name={coachName} onHide={() => setPayDialogOpen(false)} amount={pendingAmount} onSubmit={() => {
                fetchPayouts(id)
                setPayDialogOpen(false)
            }} data={pendingPayouts} />
        </div>
    )
}