import axios from 'axios'
import { config } from '../utils/urls'

/**
 * @description Destructure the url and adding complaint path
 */
const url = config.url.JURNI_API_URL + "/news"

export const getAllPosts = async () => {
    try {
        const response = await axios.get(url + "/feeds")
        const { data } = response
        return data
    } catch (response) {
        return response
    }
}