import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Lottie from 'react-lottie';
import animationData from '../../../assets/animations/nointernet.json'
export default function NoInternetDialog(props) {
    const { open } = props
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    return (
        <Dialog
            open={open}
            fullWidth={"lg"}

        >
            <DialogContent style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                <Lottie options={defaultOptions}
                    height={500}
                    width={300}
                />
                <br/>
                <h2>No Internet...</h2>
            </DialogContent>
        </Dialog>
    )
}