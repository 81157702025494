import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

export default function PDFDialog(props) {
    const { open, onHide, pdfUrl } = props
    
    const renderFooter = () => {
        return (
            <div>
                <Button label="Close" onClick={() => onHide()} className="p-button-text" />
            </div>
        );
    }
    return (
        <>
            <Dialog header="Document" visible={open} style={{ width: '60vw' , height:"80vh"}} footer={renderFooter()} draggable={false}  closeOnEscape={false} onHide={() => onHide()}>
            {/* <PDFReader url="" style={{width:"100%"}}  /> */}
            <embed src={pdfUrl}  style={{width:"100%", height:"100%"}} frameborder="10"></embed>
            </Dialog>
        </>
    )

}