import { ConfirmDialog, } from 'primereact/confirmdialog';
import { connect } from 'react-redux';
function RequestDialog(props) {
    const { open, onHide, actionData, onConfirm, profile } = props

    const accept = () => {
        const { status, _id } = actionData
        onConfirm({
            updatedBy: profile?.id,
            status: status,
            id: _id,
        })
    }

    return (
        <>
            <ConfirmDialog draggable={false} visible={open} onHide={() => onHide()} message={`Are you sure you want to ${actionData?.action} ${actionData?.user?.name} profile ?`}
                header={actionData?.action} icon="pi pi-exclamation-triangle" accept={() => accept()} reject={() => onHide()} />
        </>
    )

}

const mapStateToProps = (state) =>{
    return {
        profile : state.profile
    }
}

export default connect(mapStateToProps)(RequestDialog)