import axios from 'axios'
import { config } from '../utils/urls'

/**
 * @description Destructure the url and adding complaint path
 */
const url = config.url.JURNI_API_URL + "/payout"
const paymentUrl = config.url.JURNI_PAYMENT_URL
export const getAllPayouts = async () => {
    try {
        const response = await axios.get(url + "/all")
        const { data } = response
        return data
    } catch (response) {
        return response
    }
}

export const getAllCoachPayouts = async (coachId) => {
    try {
        const response = await axios.get(url + `/coach/${coachId}`)
        const { data } = response
        return data
    } catch (response) {
        return response
    }
}

export const updatePayouts = async (id, updateData) => {
    console.log(updateData)
    try {
        const response = await axios.put(url + `/update/${id}`, {
            ...updateData
        })
        const { data } = response
        return data
    } catch (response) {
        return response
    }
}

export const makePayout = async (paymentData) => {
    try {
        const response = await axios.post(paymentUrl + `/payout`,
        {
            ...paymentData,
            recipient_type: "EMAIL",
            currency: "USD"
        }
        )
        const { data } = response
        return data
    } catch (response) {
        return response
    }
}