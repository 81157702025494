import { settlementPercentage } from "../../constants"
export const initialFilterByStatus = (filter, data) => {
    console.log(data,"-----", filter)
    var Amount = 0
    var filteredData = []
    data.map((dataElement) => {
        if (filter === dataElement.status) {
            filteredData.push(dataElement)
            Amount += dataElement?.transactionId?.totalAmount
        }
        return null
    })

    return { filterData: filteredData, amount: Amount * (settlementPercentage / 100) }
}

export const filterByStatus = (filter, data) => {
    const filteredData = data.filter((dataElement) => filter === dataElement.status)
    return filteredData
}

