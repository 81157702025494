import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { useForm, Controller } from 'react-hook-form';
import { classNames } from 'primereact/utils';
import { connect } from 'react-redux';

function RequestRejectDialog(props) {
    const { open, onHide, onReject, actionData, profile } = props
    const defaultValues = {
        reason: '',
    }
    const onSubmit = (data) => {
        const { status, _id } = actionData
        onReject({
            updatedBy: profile?.id,
            status: status,
            id: _id,
            rejectReason: data?.reason
        })
    }
    const { control, formState: { errors }, handleSubmit, reset } = useForm({ defaultValues });
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };
    useEffect(() => {
        reset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])
    return (
        <Dialog header="Reject Profile" visible={open} onHide={() => onHide()} breakpoints={{ '960px': '75vw' }} draggable={false} focusOnShow={false} closeOnEscape={false}
            style={{ width: '50vw' }} >
            <p>Are you sure want reject <b>{actionData?.user?.name}</b>'s profile ?.</p>
            <br />
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                <div className="p-field">
                    <span className="p-float-label">
                        <Controller name="reason" control={control} rules={{ required: 'Reason is required.' }} render={({ field, fieldState }) => (
                            <InputText id={field.name} autoComplete="off" minLength={5} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
                        )} />
                        <label htmlFor="reason" className={classNames({ 'p-error': errors.name })}>Reason*</label>
                    </span>
                    {getFormErrorMessage('reason')}
                </div>
                <div style={{ display: "flex", margin: "2rem 0 0 4rem", justifyContent: "end" }}>
                    <Button label="Reject" style={{ width: "15%" }} type={"submit"} className="p-button-danger" />
                    <Button label="Close" style={{ width: "15%" }} onClick={() => onHide()} className="p-button-text" />
                </div>
            </form>
        </Dialog>
    )
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile
    }
}
export default connect(mapStateToProps)(RequestRejectDialog)