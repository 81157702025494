import React from "react";
import { Button } from "primereact/button";

function IconButton(props) {
    const { icon, label, styles, className, onClick } = props
    return (
        <>
            <Button icon={icon} label={label} style={styles} className={className} onClick={onClick} />
        </>
    )
}

export default IconButton