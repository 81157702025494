import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import dateFormat from "dateformat";
import Table from '../../Table/Table';
import { ProgressSpinner } from 'primereact/progressspinner';
import { confirmDialog } from 'primereact/confirmdialog';

//services
import { makePayout, updatePayouts } from "../../../services/payoutService";

function PayoutDialog({ onHide, open, onSubmit, data, amount, name }) {
    //const [isLoading, setIsLoading] = useState(false)
    const [isPaying, setIsPaying] = useState(false)
    const [isPaid, setIsPaid] = useState(false)
    const [isUpdated, setIsUpdated] = useState(false)
    const columns = [
        { title: "Amount", field: "bookingId.totalAmount", },
        {
            title: "Settlement",
            render: (rowData) => {
                return (
                    <span>
                        {rowData?.bookingId?.totalAmount * .6}
                    </span>
                )
            }
        },
        {
            title: "Billed on",
            field: "createdAt",
            sort: true,
            render: (rowData) => {
                return (
                    <div>
                        <p style={{ margin: "0px" }}>{dateFormat(rowData.createdAt, "mmm dS, yyyy ")}
                            <p style={{ color: "#999999", margin: "0px" }}>
                                {dateFormat(rowData.createdAt, "shortTime")}
                            </p>
                        </p>
                    </div>
                );
            },
        },
    ]
    const accept = () => {
        updateStatus()
    }

    const reject = () => {

    }
    const confirm = () => {
        confirmDialog({
            message: `Are you sure you want to pay ${amount}$ to ${name}?`,
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            draggable: false,
            accept,
            reject
        });
    };

    const updateStatus = async () => {
        setIsPaying(true)
        const response = await makePayout({
            amount: amount,
            receiver: data[0]?.bookingId?.coach?.email
        })
        console.log(response)
        setIsPaid(true)
        if (response?.success  === true && response?.data?.batch_header?.batch_status === "SUCCESS") {
            const array = data
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                await updatePayouts(element?._id, {
                    status: response?.data?.items[0]?.transaction_status,
                    payoutBatchId: response?.data?.batch_header?.payout_batch_id,
                    payoutItemId: response?.data?.items[0]?.payout_item_id,
                    payoutCharges: response?.data?.batch_header?.fees?.value / data?.length
                })
            }

            setIsUpdated(true)
        } else {
            onHide()
        }
        setTimeout(() => {
            onSubmit()
            setIsPaid(false)
            setIsPaying(false)
        }, 1500);
    }
    useEffect(() => {

    }, [open])
    return (
        <Dialog header={"Payment"} visible={open} onHide={() => { onHide() }} breakpoints={{ '960px': '75vw' }} draggable={false} focusOnShow={false} closeOnEscape={false}
            style={{ width: '50vw' }} >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                < div style={{ lineHeight: "1.6" }}>
                    <div style={{ display: "flex", alignItems: "center", fontSize: 20 }}>
                        Coach Name : <b style={{ fontSize: 20 }}>&nbsp; {name}</b>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", fontSize: 20 }}>
                        Payment amount : <b style={{ fontSize: 20 }}> &nbsp; {amount} $</b>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", fontSize: 20 }}>
                        No of payments : <b style={{ fontSize: 20 }}> &nbsp; {data?.length} </b>
                    </div>
                </ div>
                <div style={{ marginRight: "20px", display: "flex", alignItems: "center" }}>
                    {amount > 0 && !isPaying && <Button onClick={confirm} label='PAY' />}
                </div>
            </div>
            <br />
            {isPaying ?
                <>
                    <div style={{ margin: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {!isPaid ? <ProgressSpinner style={{ width: '30px', height: '30px', margin: "0px 10px" }} animationDuration={.1} strokeWidth="4" /> :
                            <i style={{ fontSize: 30, color: "#139e06", margin: "0px 10px" }} className="pi pi-check-circle"></i>}
                        <h3 style={{ margin: "0" }}>Sending Payouts</h3>
                    </div>
                    <div style={{ margin: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {!isUpdated ? <ProgressSpinner style={{ width: '30px', height: '30px', margin: "0px 10px" }} animationDuration={.1} strokeWidth="4" /> :
                            <i style={{ fontSize: 30, color: "#139e06", margin: "0px 10px" }} className="pi pi-check-circle"></i>}
                        <h3 style={{ margin: "0" }}>Updating Payouts</h3>
                    </div>
                </>
                :
                <div style={{}}>
                    <Table data={data} columns={columns} style={{ maxHeight: "50vh" }} />
                </ div>
            }
        </Dialog>
    )
}
export default PayoutDialog