import firebase from "firebase/app";
import "firebase/analytics";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyCSh5S3rHb3o01vdhxsyqVdwq5LmyE4r_4",
    authDomain: "jurni-admin.firebaseapp.com",
    projectId: "jurni-admin",
    storageBucket: "jurni-admin.appspot.com",
    messagingSenderId: "1076973072451",
    appId: "1:1076973072451:web:5f01e65e3fe8739d1464f6",
    measurementId: "G-BLHVBTQ5S5"
  };

firebase.initializeApp(firebaseConfig);

export const firebaseAnalytics = firebase.analytics();