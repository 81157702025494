import axios from "axios";
import { config } from '../utils/urls'

/**
 * @description Destructure the url and adding complaint path
 */
const url = config.url.JURNI_API_URL + "/coach"
const userUrl = config.url.JURNI_API_URL + "/user"

export const getAllCoaches = async () => {
    try {
        const response = await axios.get(url + "/allcoaches")
        const { data } = response
        return data
    } catch (error) {
        return {
            success: false
        }
    }

}

export const updateCoachStatus = async (requestData) => {
    try {
        const response = await axios.post(`${userUrl}/update`, requestData)
        const { data } = response
        return data
    } catch (response) {
        return response
    }
}