import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";

import SnackbarContent from "@material-ui/core/SnackbarContent";
import componentStyles from "../../assets/theme/components/snackbar.js";
const useStyles = makeStyles(componentStyles);


/**
 * @description Snack bar with coloe theme
 * @param {*} props ={message:String, open:Boolean, handleClose:function} 
 * @returns snackbar
 * 
 */
export function AlertSnackbar(props) {
    const { message, open, handleClose, variant } = props
    const classes = useStyles();

    /**
     * 
     * @desc Classes for the snackbar
     */
    // const defaultSnackbarRootClasses = { root: classes.defaultSnackbar };
    // const primarySnackbarRootClasses = { root: classes.primarySnackbar };
    // const secondarySnackbarRootClasses = { root: classes.secondarySnackbar };
    // const infoSnackbarRootClasses = { root: classes.infoSnackbar };
    // const successSnackbarRootClasses = { root: classes.successSnackbar };
    // const errorSnackbarRootClasses = { root: classes.errorSnackbar };
    // const warningSnackbarRootClasses = { root: classes.warningSnackbar };
    const getStyles = () => {
        var styleClass = null
        switch (variant) {
            case "success":
                styleClass = { root: classes.successSnackbar }
                break;

            case "warning":
                styleClass = { root: classes.warningSnackbar }
                break;

            case "error":
                styleClass = { root: classes.errorSnackbar }
                break;

            case "primary":
                styleClass = { root: classes.primarySnackbar }
                break;
            default:
                break;
        }
        return styleClass
    }
    return (
        <div className={classes.root}>
            <Snackbar style={{ height: "100px" }} open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                <SnackbarContent
                    elevation={0}
                    classes={getStyles()}
                    message={
                        <>
                            {message}
                        </>
                    }
                    action={
                        <Box
                            component={IconButton}
                            padding="0!important"
                            onClick={() => handleClose(false)}
                        >
                            <Box
                                component="span"
                                color={"#f6f6f6"}
                            >
                                ×
                            </Box>
                        </Box>
                    }
                />
            </Snackbar>
        </div>
    );
}

