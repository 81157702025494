import axios from "axios";
import { config } from '../utils/urls'

/**
 * @description Destructure the url and adding complaint path
 */
const url = config.url.JURNI_API_URL + "/auth"

// Add a response interceptor
axios.interceptors.response.use(function (response) {

    return response;
}, function (error) {
    alert("Server error")
    return Promise.reject(error);
});

export const login = async (authData) => {
    const { username, password } = authData
    try {
        const response = await axios.post(url + "/login/admin", {
            email: username,
            password: password
        })
        return response?.data
    } catch (error) {
        return error
    }
}