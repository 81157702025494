import React from "react";

// @material-ui/core components
// import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

// core components
//import componentStyles from "../../assets/theme/components/auth-footer.js";

// const useStyles = makeStyles(componentStyles);

const Footer = () => {
  // const classes = useStyles();
  return (
    <Box component="footer" width="100%" paddingTop="1rem" style={{ textAlign: "center" }}>
      © 2021 All Rights Reserved By Just One Services LLC | Made with ❤️ By AGE


    </Box>
  );
};

export default Footer;
