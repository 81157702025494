import axios from "axios";
import { config } from '../utils/urls'

const url = `${config.url.JURNI_API_URL}/category`

export const getAllCategory = async () => {
    try {
        const response = await axios.get(`${url}/getAll?isAdmin=true`)
        const { data } = response
        return data
    } catch (error) {
        return {
            success: false
        }
    }
}

export const addCategory = async (payloadData) => {
    try {
        const response = await axios.post(`${url}/add`,payloadData)
        const { data } = response
        return data
    } catch (error) {
        return {
            success: false
        }
    }
}


export const updateCategory = async (updateData) => {
    try {
        const response = await axios.put(`${url}/update/${updateData?.id}`, updateData)
        const { data } = response
        return data
    } catch (response) {
        return response
    }
}