import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "../../../assets/theme/components/page-header.js";
import dateFormat from "dateformat";
import { SRLWrapper } from "simple-react-lightbox";

/**
 * @description Importing styles through material ui
 */
// import DangerButton from '../../../components/Buttons/DangerButton';
import { AlertSnackbar } from '../../../components/Toasts/toasts.js'
import { OutlineButton, ContainedButton } from "../../../components/Buttons/Button.js";
import { StoriesTable } from "../../../components/Table/StoriesTable.js";
import { getComplaints, updateComplaints } from "../../../services/complaintService.js";
import Loader from "../../../components/Loader/Loader.js";
import StoryDialog from "../../../components/Dialogs/Complaints/storyDialog.js";
import { filterByDate } from "../../../utils/FilterByDate/filterByDate.js";
import { filterByType, addFilter } from '../../../utils/FilterbyType/filterByType';
import ToolbarTab from '../../../components/Toolbar/Toolbar';
import { Chip } from 'primereact/chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import './news.css'
const useStyles = makeStyles(componentStyles);
export default function News(props) {
    const [news, setNews] = useState([])
    const [copyNews, setCopyNews] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [actionDialog, setActionDialog] = useState(false)
    const [actionData, setActionData] = useState({})
    const [openToast, setOpenToast] = useState(false)
    const [toastData, setToastData] = useState({
        message: "",
        variant: ""
    })
    const [filter, setFilter] = useState(["REQUESTED"])
    const classes = useStyles()
    const getNews = async () => {
        setIsLoading(true)
        const response = await getComplaints({
            category: "NEWS",
            flagType: "REPORTED"
        })
        if (response?.success) {
            setNews(filterByType(filter, response.data))
            setCopyNews(response.data)
        } else {
            setOpenToast(true)
            setToastData({
                message: "Cannot fetch news complaints.",
                variant: "error"
            })
        }
        setIsLoading(false)
    }
    useEffect(() => {
        getNews()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const filterHandler = (data) => {
        setIsLoading(true)
        const filterResponse = filterByDate(data, copyNews)
        setNews(filterResponse)
        setIsLoading(false)
    }
    const columns = [
        {
            title: "Post",
            render: (rowData) => {
                return (
                    <img style={{ cursor: "zoom-in" }} src={rowData?.news?.data} alt="Post" height={"100px"} />
                )
            },
        },
        {
            title: "Posted by", field: "news.user.name",
            render: (rowData) => {
                return (
                    <p style={{ margin: "0px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", }}>
                        {rowData?.news?.user?.name}
                    </p>
                )
            }
        },
        { title: "Flagged by", field: "flaggedBy.name" },
        {
            title: "Posted on",
            render: (rowData) => {
                return (<p style={{ margin: "0px" }}>{dateFormat(rowData.createdAt, "mmm dS, yyyy ")}
                    <p style={{ color: "#999999", margin: "0px" }}>
                        {dateFormat(rowData.createdAt, "shortTime")}
                    </p>
                </p>)
            }
        },
        {
            title: "Actions",
            render: (rowData) => {
                if (rowData?.status === "APPROVED" || rowData?.status === "REJECTED") {
                    if (rowData?.status === "APPROVED") {
                        return (
                            <div className="action-box">
                                <CheckCircleIcon fontSize="medium" color={"success"} />  Approved
                            </div>
                        )
                    } else {
                        return (
                            <div className="action-box">
                                <CancelIcon fontSize="medium" color={"error"} /> Rejected
                            </div>

                        )
                    }
                } else {
                    return (
                        <div className="request-buttons">
                            <div > <ContainedButton color="Primary" variant="contained" onClick={() => {
                                setActionData({ ...rowData, status: "APPROVED" })
                                setActionDialog(true)
                            }} label="Accept" /></div> &nbsp;&nbsp;<br />
                            <div onClick={() => {
                                setActionData({ ...rowData, status: "REJECTED" })
                                setActionDialog(true)
                            }} ><OutlineButton styleClass={"buttonErrorClasses"} variant="outlined" label="Reject" /></div>
                        </div>
                    )
                }
            }
        },
    ]
    const actionHandler = async (data) => {
        setActionDialog(false)
        setIsLoading(true)
        const response = await updateComplaints(data)
        const { success } = response
        const status = (data?.status).toLowerCase();
        if (success) {
            setIsLoading(false)
            getNews()
            setOpenToast(true)
            setToastData({
                message: `News ${status} successfully`,
                variant: "success"
            })
        } else {
            setIsLoading(false)
            setOpenToast(true)
            setToastData({
                message: `News cannot be ${status}`,
                variant: "error"
            })
        }
    }
    const filterHandlerType = (value) => {
        const response = addFilter(filter, value)
        setFilter(response)
        setNews(filterByType(response, copyNews))
    }
    const leftTabContents = (
        <React.Fragment>
            <div onClick={() => {
                filterHandlerType("REQUESTED")
            }}>
                <Chip label="Requested" style={(filter?.includes("REQUESTED")) ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className={"toolbarButton"} />
            </div>
            <div onClick={() => {
                filterHandlerType("APPROVED")
            }}>
                <Chip label="Accepted" style={(filter?.includes("APPROVED")) ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className="toolbarButton" />
            </div>
            <div onClick={() => {
                filterHandlerType("REJECTED")
            }}><Chip label="Rejected" style={(filter?.includes("REJECTED")) ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className="toolbarButton" /></div>
        </React.Fragment>
    )

    return (
        <div>
            <div className={classes.header} />
            <br />
            <ToolbarTab leftContents={leftTabContents} />
            <SRLWrapper>
                <StoriesTable onFilter={(data) => {
                    filterHandler(data)
                }} onClearFilter={() => setNews(copyNews)} data={news} columns={columns} expandable />
            </SRLWrapper>
            <Loader open={isLoading} />
            <StoryDialog open={actionDialog} onHide={() => setActionDialog(false)} actionData={{
                ...actionData,
                header: "News"
            }} onAction={(data) => {
                actionHandler(data)
            }} />
            <AlertSnackbar open={openToast} handleClose={() => setOpenToast(false)} variant={toastData.variant} message={toastData.message} />
            <div>
            </div>
        </div>
    )
}