import React, { useState, useEffect } from 'react'
import RequestTable from '../../components/Table/RequestTable';
import componentStyles from "../../assets/theme/components/page-header.js";
import { makeStyles } from "@material-ui/core/styles";
import Loader from '../../components/Loader/Loader';
import { Tag } from 'primereact/tag';
import Avatar from '@material-ui/core/Avatar';
import RequestDialog from '../../components/Dialogs/RequestDialog';
import { getAllCoachRequests, updateCoachRequests } from '../../services/requestService';
import dateFormat from "dateformat";
import './request.css'
import PDFDialog from "../../components/Dialogs/PdfDialog.js";
import { Button } from 'react-bootstrap';
import { AlertSnackbar } from '../../components/Toasts/toasts';
import { Tooltip } from '@material-ui/core';
import RequestRejectDialog from '../../components/Dialogs/RequestRejectDialog';
import { filterByDate } from '../../utils/FilterByDate/filterByDate.js'
import { filterByType, addFilter } from '../../utils/FilterbyType/filterByType';
import ToolbarTab from '../../components/Toolbar/Toolbar';
import { Chip } from 'primereact/chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
const useStyles = makeStyles(componentStyles);

export default function Requests(props) {
    const classes = useStyles()
    const [acceptDialog, setAcceptDialog] = useState(false)
    const [rejectDialog, setRejectDialog] = useState(false)
    const [actionData, setActionData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [coachRequests, setCoachRequests] = useState([])
    const [copyRequests, setCopyRequests] = useState([])
    const [documentUrl, setDocumentUrl] = useState("")
    const [opnPdf, setOpenPdf] = useState(false)
    const [openToast, setOpenToast] = useState(false)
    const [toastData, setToastData] = useState({
        message: "",
        variant: ""
    })
    const [filter, setFilter] = useState(["REQUESTED"])
    const getRequests = async () => {
        setIsLoading(true)
        const requestResponse = await getAllCoachRequests()
        if (requestResponse?.success) {
            setCoachRequests(filterByType(filter, requestResponse.data))
            setCopyRequests(requestResponse.data)
        } else {
            setOpenToast(true)
            setToastData({
                message: "Cannot fetch coaches.",
                variant: "error"
            })
        }
        setIsLoading(false)
    }
    const columns = [
        {
            title: "Name", sort: true, field: "user.name",
            render: (rowData) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", alignContent: "center" }}>
                            {rowData?.user?.dp ? <Avatar src={rowData?.user?.dp} className="p-mr-2" size="xlarge" shape="circle" /> :
                                <Avatar icon="pi pi-user" className="p-mr-2" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} shape="circle" />}
                        </div>
                        <Tooltip title={rowData?.user?.name} >
                            <p style={{ margin: " 0 0 0 .65rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", }}>
                                {rowData?.user?.name}
                            </p>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            title: "Email", field: "email", sort: true,
            render: (rowData) => {
                return (
                    <Tooltip title={rowData?.user?.email} >
                        <p style={{ margin: " 0 ", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", }}>
                            {rowData?.user?.email}
                        </p>
                    </Tooltip>
                )
            }
        },
        {
            title: "Document",
            render: (rowData) => {
                if (rowData?.docs[0]) {
                    return (
                        <Button variant="link" onClick={() => {
                            setDocumentUrl(rowData?.docs[0])
                            setOpenPdf(true)
                        }} >View</Button>
                    )
                } else {
                    return (
                        <p style={{ margin: "0px" }}> Documents not uploaded. </p>
                    )
                }
            }
        },
        {
            title: "Date", field: "createdAt",
            render: (rowData) => {
                return (
                    <div>
                        <p style={{ margin: "0px" }}>{dateFormat(rowData.createdAt, "mmm dS, yyyy ")}
                            <p style={{ color: "#999999", margin: "0px" }}>
                                {dateFormat(rowData.createdAt, "shortTime")}
                            </p>
                        </p>
                    </div>
                )
            }
        },
        {
            title: "Actions ",
            render: (rowData) => {
                if (rowData?.status === "APPROVED" || rowData?.status === "REJECTED") {
                    if (rowData?.status === "APPROVED") {
                        return (
                            <div className="action-box">
                                <CheckCircleIcon fontSize="medium" color={"success"} /> &nbsp; Approved
                            </div>
                        )
                    } else {
                        return (
                            <div className="action-box">
                                <CancelIcon fontSize="medium" color={"error"} /> &nbsp; Rejected
                            </div>

                        )
                    }
                }
                else {
                    return (
                        <div className="request-buttons">
                            <div onClick={() => {
                                setActionData({ ...rowData, status: "APPROVED", action: "Accept" })
                                setAcceptDialog(true)
                            }}> <Tag value="Accept" style={{ backgroundColor: "#73bd7b", cursor: "pointer" }} /></div> &nbsp;&nbsp;<br />
                            <div onClick={() => {
                                setActionData({ ...rowData, status: "REJECTED" })
                                setRejectDialog(true)
                            }} ><Tag value="Reject" style={{ backgroundColor: "#fff", color: "#ff6666", border: "1px solid red", cursor: "pointer" }} /></div>
                        </div>
                    )
                }
            }
        },
    ]

    useEffect(() => {
        getRequests()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onAction = async (data) => {
        const updateResponse = await updateCoachRequests({
            ...data,
            category: (actionData?.category) ? (actionData?.category) : "N/A"
        })
        const status = (data.status).toLowerCase()
        if (updateResponse.success === true) {
            setIsLoading(false)
            getRequests()
            setOpenToast(true)
            setToastData({
                message: `Coach request ${status} successfully`,
                variant: "success"
            })
        } else {
            setIsLoading(false)
            setOpenToast(true)
            setToastData({
                message: `Coach request cannot be ${status}`,
                variant: "error"
            })
        }
    }

    const filterHandler = (data) => {
        setIsLoading(true)
        const filterResponse = filterByDate(data, copyRequests)
        setCoachRequests(filterResponse)
        setIsLoading(false)
    }
    const filterHandlerType = (value) => {
        const response = addFilter(filter, value)
        setFilter(response)
        setCoachRequests(filterByType(response, copyRequests))
    }
    const leftTabContents = (
        <React.Fragment>
            <div onClick={() => {
                filterHandlerType("REQUESTED")
            }}>
                <Chip label="Requested" style={(filter?.includes("REQUESTED")) ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className={"toolbarButton"} />
            </div>
            <div onClick={() => {
                filterHandlerType("APPROVED")
            }}>
                <Chip label="Accepted" style={(filter?.includes("APPROVED")) ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className="toolbarButton" />
            </div>
            <div onClick={() => {
                filterHandlerType("REJECTED")
            }}><Chip label="Rejected" style={(filter?.includes("REJECTED")) ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className="toolbarButton" /></div>
            {/* <div onClick={()=>{
               filterHandlerType("CANCELLED")
           }}><Chip label="Cancelled" style={(filter?.includes("CANCELLED"))?{backgroundColor: "rgb(94,113,228)", color:"#fff"}:{}} className="toolbarButton" /></div> */}
        </React.Fragment>
    )

    return (
        <div >
            <div className={classes.header} />
            <br />
            <ToolbarTab leftContents={leftTabContents} />
            <RequestTable columns={columns} data={coachRequests} expandable onFilter={(data) => {
                filterHandler(data)
            }} onClearFilter={() => setCoachRequests(copyRequests)} type={"Request"} />
            <Loader open={isLoading} />
            <RequestDialog open={acceptDialog} actionData={actionData} onHide={() => { setAcceptDialog(false) }} onConfirm={(data) => {
                onAction(data)
            }} />
            <RequestRejectDialog open={rejectDialog} actionData={actionData} onHide={() => { setRejectDialog(false) }} onReject={(data) => {
                //data with reason = {reason:"reason given by admin"}
                onAction(data)
                setRejectDialog(false)
            }} />
            <PDFDialog pdfUrl={documentUrl} open={opnPdf} onHide={() => {
                setOpenPdf(false)
                setDocumentUrl("")
            }} />
            <AlertSnackbar open={openToast} handleClose={() => setOpenToast(false)} variant={toastData.variant} message={toastData.message} />
        </div>
    )
}