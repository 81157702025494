const prod = {
    url : {
        JURNI_API_URL : "https://api.myjurni.org",
        JURNI_PAYMENT_URL : "https://pjurni.myjurni.org",
        JURNI_NOTIFICATION_URL : ""
    }
}
const dev = {
    url : {
        JURNI_API_URL : "https://jurni.herokuapp.com",
        JURNI_PAYMENT_URL : "http://localhost:5051",
        JURNI_NOTIFICATION_URL : ""
    }
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod;