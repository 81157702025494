import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "../../assets/theme/components/button.js";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(componentStyles);

export  function ContainedButton(props) {
    const classes = useStyles();
    const {label,onClick, variant,styleClass, color } = props
    const getClass = () =>{
        var finalClassName ={}
        switch (styleClass) {
            case "buttonInfoClasses" :
               finalClassName =classes.buttonContainedInfo
               break;
            case "buttonSuccessClasses":
                finalClassName =   classes.buttonContainedSuccess
                break;
            case "buttonErrorClasses":
                finalClassName =  {
                    root: classes.buttonContainedError,
                  }
                break;
            case "buttonWarningClasses":
                finalClassName =  {
                    root: classes.buttonContainedWarning,
                  }
                break;
        
            default:
                break;
        }
        return finalClassName
    }
      
    
    
    return(
        <Button variant={variant} size="small" color={color} onClick={onClick} classes={getClass()}> {label} </Button>
    )
}

export  function OutlineButton(props) {
    const classes = useStyles();
    const {label,onClick, variant,styleClass, color } = props
    const getClass = () =>{
        var finalClassName ={}
        
        switch (styleClass) {
            case "buttonInfoClasses" :
               finalClassName =classes.buttonOutlineInfo
               break;
            case "buttonSuccessClasses":
                finalClassName =   classes.buttonOutlineSuccess
                break;
            case "buttonErrorClasses":
                finalClassName =  {
                    root: classes.buttonOutlineError,
                  }
                break;
            case "buttonWarningClasses":
                finalClassName =  {
                    root: classes.buttonOutlineWarning,
                  }
                break;
        
            default:
                break;
        }
        return finalClassName
    }
      
    
    
    return(
        <Button variant={variant} size="small" color={color} onClick={onClick} classes={getClass()}> {label} </Button>
    )
}