import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import './table.css'

export const Table = (props) => {
    const { data, columns, scrollable, scrollableHeight, style } = props
    const [globalFilter, setGlobalFilter] = useState("")
    const header = (
        <div className="table-header" style={{ display: "flex", justifyContent: "flex-end", }}>
            {<>

            </>}
            &nbsp;&nbsp;&nbsp;
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
            </span>

        </div>
    );
    
    return (
        <div className="datatable-responsive-demo" >
            <DataTable value={data}
                header={header}
                scrollable={scrollable}
                scrollHeight={scrollableHeight}
                editMode="row"
                removableSort
                className="p-datatable-responsive-demo "
                sortMode="single"
                // showGridlines
                style={{ fontSize: "0.9rem", ...style, }}
                rows={10}
                dataKey="_id"
                // expandedRows={expandedRows}
                // onRowToggle={(e) => {
                //     setExpandedRows(e.data)
                // }}
                globalFilter={globalFilter}
            // onRowExpand={onRowExpand} rowExpansionTemplate={rowExpansionTemplate}
            // onRowCollapse={onRowCollapse}
            >
                {columns && columns.map((column) => {
                    return (
                        <Column style={{
                            ...column.style,
                            textAlign: "center",
                            fontWeight: 500
                        }
                        }
                            key={column._id}
                            field={column.field} header={column.title} headerStyle={{ fontSize: "28px !important", textAlign: "center", fontWeight: 700, backgroundColor: "#E6EAED" }}
                            body={column.render ? column.render : false}
                            sortable={column.sort ? column.sort : false}
                        />
                    )
                })}
            </DataTable>

        </div >

    )
}
export default Table