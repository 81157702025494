
const componentStyles = (theme) => ({
    header: {
      position: "relative",
      //background: "rgb(15,170,214)",
      background: "linear-gradient(15deg, rgba(15,170,214,1) 0%, rgba(165,196,16,1) 100%)",
      paddingBottom: "1rem",
      paddingTop: "1rem",
      [theme.breakpoints.up("md")]: {
        paddingTop: "4rem",
      },
    },
    containerRoot: {
      [theme.breakpoints.up("md")]: {
        paddingLeft: "39px",
        paddingRight: "39px",
      },
    },
  });
  
  export default componentStyles;
  