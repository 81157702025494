import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "../../../assets/theme/components/page-header.js";
import { Tooltip } from '@material-ui/core';
import dateFormat from "dateformat";

/**
 * @description Importing styles through material ui
 */
// import DangerButton from '../../../components/Buttons/DangerButton';
import { AlertSnackbar } from '../../../components/Toasts/toasts.js'
import { OutlineButton, ContainedButton } from "../../../components/Buttons/Button.js";
import { UserTable } from "../../../components/Table/UserTable.js";
import { getComplaints, updateComplaints } from "../../../services/complaintService.js";
import Loader from "../../../components/Loader/Loader.js";
import StoryDialog from "../../../components/Dialogs/Complaints/storyDialog.js";
import Avatar from '@material-ui/core/Avatar';
import { filterByDate } from '../../../utils/FilterByDate/filterByDate.js'
import { filterByType, addFilter } from '../../../utils/FilterbyType/filterByType';
import ToolbarTab from '../../../components/Toolbar/Toolbar';
import { Chip } from 'primereact/chip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
const useStyles = makeStyles(componentStyles);
export default function Users(props) {
    const [users, setUsers] = useState([])
    const [copyUsers, setCopyUsers] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [actionDialog, setActionDialog] = useState(false)
    const [actionData, setActionData] = useState({})
    const [openToast, setOpenToast] = useState(false)
    const [toastData, setToastData] = useState({
        message: "",
        variant: ""
    })
    const [filter, setFilter] = useState(["REQUESTED"])
    const classes = useStyles()
    const getStories = async () => {
        setIsLoading(true)
        const storiesResponse = await getComplaints({
            category: "USER",
            flagType: "REPORTED"
        })
        if (storiesResponse.success) {
            setUsers(filterByType(filter, storiesResponse.data))
            setCopyUsers(storiesResponse.data)
        } else {
            setOpenToast(true)
            setToastData({
                message: "Cannot fetch news complaints.",
                variant: "error"
            })
        }
        setIsLoading(false)
    }
    useEffect(() => {
        getStories()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const columns = [
        {
            title: "Profile", field: "name",
            render: (rowData) => {
                return (
                    <div style={{ display: "flex", alignItems: "center", }}>
                        <div style={{ display: "flex", alignContent: "center" }}>
                            {rowData?.user?.dp ? <Avatar src={rowData?.user?.dp} className="p-mr-2" size="xlarge" shape="circle" /> :
                                <Avatar icon="pi pi-user" className="p-mr-2" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} shape="circle" />}
                        </div>
                        <Tooltip title={rowData?.user?.name} >
                            <p style={{ margin: " 0 0 0 .65rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", }}>
                                {rowData?.user?.name}
                            </p>
                        </Tooltip>
                    </div>
                )
            },
        },
        { title: "Email", field: "user.email" },
        { title: "Flagged by", field: "flaggedBy.name" },
        {
            title: "Date", field: "createdAt",
            render: (rowData) => {
                return (
                    <div>
                        <p style={{ margin: "0px" }}>{dateFormat(rowData.createdAt, "mmm dS, yyyy ")}
                            <p style={{ color: "#999999", margin: "0px" }}>
                                {dateFormat(rowData.createdAt, "shortTime")}
                            </p>
                        </p>
                    </div>
                )
            }
        },
        {
            title: "Actions",
            render: (rowData) => {
                if (rowData?.status === "APPROVED" || rowData?.status === "REJECTED") {
                    if (rowData?.status === "APPROVED") {
                        return (
                            <div className="action-box">
                                <CheckCircleIcon fontSize="medium" color={"success"} /> &nbsp; Approved
                            </div>
                        )
                    } else {
                        return (
                            <div className="action-box">
                                <CancelIcon fontSize="medium" color={"error"} /> &nbsp; Rejected
                            </div>

                        )
                    }
                } else {
                    return (
                        <div className="request-buttons">
                            <div > <ContainedButton color="Primary" variant="contained" onClick={() => {
                                setActionData({ ...rowData, status: "APPROVED" })
                                setActionDialog(true)
                            }} label="Accept" /></div> &nbsp;&nbsp;<br />
                            <div onClick={() => {
                                setActionData({ ...rowData, status: "REJECTED" })
                                setActionDialog(true)
                            }} ><OutlineButton styleClass={"buttonErrorClasses"} variant="outlined" label="Reject" /></div>
                        </div>
                    )
                }
            }
        },
    ]
    const actionHandler = async (data) => {
        setActionDialog(false)
        setIsLoading(true)
        const response = await updateComplaints(data)
        const { success } = response
        const status = (data?.status).toLowerCase();
        if (success) {
            setIsLoading(false)
            getStories()
            setOpenToast(true)
            setToastData({
                message: `News ${status} successfully`,
                variant: "success"
            })
        } else {
            setIsLoading(false)
            setOpenToast(true)
            setToastData({
                message: `News cannot be ${status}`,
                variant: "error"
            })
        }
    }
    const filterHandler = (data) => {
        setIsLoading(true)
        const filterResponse = filterByDate(data, copyUsers)
        setUsers(filterResponse)
        setIsLoading(false)
    }

    const filterHandlerType = (value) => {
        const response = addFilter(filter, value)
        setFilter(response)
        setUsers(filterByType(response, copyUsers))
    }
    const leftTabContents = (
        <React.Fragment>
            <div onClick={() => {
                filterHandlerType("REQUESTED")
            }}>
                <Chip label="Requested" style={(filter?.includes("REQUESTED")) ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className={"toolbarButton"} />
            </div>
            <div onClick={() => {
                filterHandlerType("APPROVED")
            }}>
                <Chip label="Approved" style={(filter?.includes("APPROVED")) ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className="toolbarButton" />
            </div>
            <div onClick={() => {
                filterHandlerType("REJECTED")
            }}><Chip label="Rejected" style={(filter?.includes("REJECTED")) ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className="toolbarButton" /></div>
        </React.Fragment>
    )
    return (
        <div>
            <div className={classes.header} />
            <br />
            <ToolbarTab leftContents={leftTabContents} />
            <UserTable data={users} columns={columns} expandable onFilter={(data) => {
                filterHandler(data)
            }} onClearFilter={() => setUsers(copyUsers)} />
            <Loader open={isLoading} />
            <StoryDialog open={actionDialog} onHide={() => setActionDialog(false)} actionData={{
                ...actionData,
                header: "User"
            }} onAction={(data) => {
                actionHandler(data)
            }} />
            <AlertSnackbar open={openToast} handleClose={() => setOpenToast(false)} variant={toastData.variant} message={toastData.message} />
        </div>
    )
}
