import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import Loader from '../../Loader/Loader';

//services
import { getAllCoaches } from '../../../services/coachService'
import Table from '../../Table/Table';

function CoachesDialog({ onHide, open, onSubmit }) {
    const [isLoading, setIsLoading] = useState(false)
    const [coaches, setCoaches] = useState([])
    const fetchCoaches = async () => {
        setIsLoading(true)
        const response = await getAllCoaches()
        const { success, data } = response
        if (success === true) {
            setCoaches(data)
            console.log("---++++++++", data)
        } else {
            // setOpenToast(true)
            // setToastData({
            //     message: "Cannot fetch Coaches.",
            //     variant: "error"
            // })
        }
        setIsLoading(false)
    }
    const columns = [
        { title: "Coach Name", field: "name" },
        { title: "Email", field: "email", },
        // { title: "Phone Number", field: "phoneNumber", },
        {
            title: "Action",
            render: (rowData) => {
                return (
                    <>
                        <Button onClick={() => {
                            onSubmit(rowData?._id, rowData?.name)
                        }} label='Pay' />
                    </>
                )
            }
        }
    ]
    useEffect(() => {
        fetchCoaches()
    }, [])
    return (
        <Dialog header={"Select a coach"} visible={open} onHide={() => onHide()} breakpoints={{ '960px': '75vw' }} draggable={false} focusOnShow={false} closeOnEscape={false}
            style={{ width: '50vw' }} >
            <Table data={coaches} columns={columns} scrollable={true} scrollableHeight={"75vh"} />
            <Loader open={isLoading} />
        </Dialog>
    )
}
export default CoachesDialog