import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { Chip } from 'primereact/chip';
import {
    Avatar,
    Tooltip
} from '@material-ui/core';

// Components
import PayoutTable from "../../../components/Table/PayoutTable";
import Loader from '../../../components/Loader/Loader';
import { AlertSnackbar } from '../../../components/Toasts/toasts';
import ToolbarTab from '../../../components/Toolbar/Toolbar';
import IconButton from '../../../components/Buttons/IconButton'
import CoachesDialog from "../../../components/Dialogs/Coaches";

// Services
import { getAllPayouts } from '../../../services/payoutService'

// Styles
import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "../../../assets/theme/components/page-header.js";

// Utils
import { filterByStatus } from '../../../utils/FilterTransactions'
import { settlementPercentage } from '../../../constants';
import { firebaseAnalytics } from "../../../analytics/firebase";
// Icons 
import { FaCcPaypal } from "react-icons/fa";
import { FaCcStripe } from "react-icons/fa";

const useStyles = makeStyles(componentStyles);
export default function Payouts(props) {
    const [payouts, setPayouts] = useState([])
    const [copyPayouts, setCopyPayouts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [coachesDialogOpen, setCoachesDialogOpen] = useState(false)
    const [openToast, setOpenToast] = useState(false)
    const [toastData, setToastData] = useState({
        message: "",
        variant: ""
    })
    const [filter, setFilter] = useState("All")
    const classes = useStyles()
    const fetchPayouts = async () => {
        setIsLoading(true)
        const response = await getAllPayouts()
        const { success, data } = response
        if (success === true) {
            setPayouts(data)
            console.log(data)
            setCopyPayouts(data)
        } else {
            setOpenToast(true)
            setToastData({
                message: "Cannot fetch payouts.",
                variant: "error"
            })
        }
        setIsLoading(false)
    }
    const columns = [
        { title: "Coach Name", field: "bookingId.coach.name", 
    render:(rowData)=>{
        return(
            <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", alignContent: "center" }}>
                {rowData?.bookingId?.coach?.dp ? <Avatar src={rowData?.bookingId?.coach?.dp} className="p-mr-2" size="xlarge" shape="circle" /> :
                    <Avatar icon="pi pi-user" className="p-mr-2" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} shape="circle" />}
            </div>
            <Tooltip title={rowData?.bookingId?.coach?.name} >
                <p style={{ margin: " 0 0 0 .65rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", }}>
                    {rowData?.bookingId?.coach?.name}
                </p>
            </Tooltip>
        </div>
        )
    } },
        { title: "Amount", field: "bookingId.totalAmount", },
        {
            title: "Settlement",
            render: (rowData) => {
                return (
                    <span>
                        {rowData?.bookingId?.totalAmount * (settlementPercentage / 100)}
                    </span>
                )
            }
        },
        { title: "Payout Charge", field: "payoutCharges" },
        { title: "Currency", field: "transactionId.currency" },
        {
            title: "Payment", field: "status", render: (rowData) => {
                if (rowData?.status === "SUCCESS") {
                    return (<p style={{
                        padding: "2px", margin: "0 1rem",
                        background: "#b2fa8e", borderRadius: "10px", color: "#267300"
                    }} >
                        Success
                    </p>)
                } else if(rowData?.status === "UNCLAIMED") {
                    return (<p style={{
                        padding: "0px", margin: "0 1rem",
                        background: "#f7a199", borderRadius: "10px", color: "#9e1306"
                    }} >
                        Unclaimed
                        <br/>
                        
                    </p>)
                }
                else {
                    return (<p style={{
                        padding: "2px", margin: "0 1rem",
                        background: "#fff3c9", borderRadius: "10px", color: "#d68b00"
                    }} >
                        {rowData?.status}
                    </p>)
                }
            }
        },
        {
            title: "Gateway", field: "transactionId.gateway",
            render: (rowData) => {
                if (rowData?.transactionId?.gateway === "STRIPE") {
                    return (
                        <FaCcStripe size={35} />
                    )
                } else if (rowData?.transactionId?.gateway === "PAYPAL") {
                    return (
                        <FaCcPaypal size={35} />
                    )
                }
            }
        },
        {
            title: "Paid on",
            field: "createdAt",
            sort: true,
            render: (rowData) => {
                return (
                    <div>
                        <p style={{ margin: "0px" }}>{dateFormat(rowData.createdAt, "mmm dS, yyyy ")}
                            <p style={{ color: "#999999", margin: "0px" }}>
                                {dateFormat(rowData.createdAt, "shortTime")}
                            </p>
                        </p>
                    </div>
                );
            },
        },
    ]
    const filterHandlerType = (status) => {
        if (status === "All") {
            setFilter("All")
            setCopyPayouts(payouts)
        } else {
            setFilter(status)
            const response = filterByStatus(status, payouts)
            setCopyPayouts(response)
        }
    }

    const leftTabContents = (
        <React.Fragment >
            <div onClick={() => {
                filterHandlerType("All")
            }}>
                <Chip label="All" style={(filter === "All") ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className={"toolbarButton"} />
            </div>
            <div onClick={() => {
                filterHandlerType("PENDING")
            }}>
                <Chip label="Pending" style={(filter === "PENDING") ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className="toolbarButton" />
            </div>
            <div onClick={() => {
                filterHandlerType("UNCLAIMED")
            }}>
                <Chip label="Unclaimed" style={(filter === "UNCLAIMED") ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className="toolbarButton" />
            </div>
            <div onClick={() => {
                filterHandlerType("SUCCESS")
            }}><Chip label="Success" style={(filter === "SUCCESS") ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className="toolbarButton" />
            </div>
        </React.Fragment>
    )
    const rightTabContents = (
        <React.Fragment >
            <IconButton className="p-button-raised" label={"Settle"} onClick={() => setCoachesDialogOpen(true)} />
            &nbsp;
        </React.Fragment>
    )

    useEffect(() => {
        fetchPayouts()
        firebaseAnalytics.logEvent("payoutsPage_visited");
    }, [])
    return (
        <div>
            <div className={classes.header} />
            <ToolbarTab leftContents={leftTabContents} rightContents={rightTabContents} />
            <PayoutTable data={copyPayouts} columns={columns} />
            <Loader open={isLoading} />
            <AlertSnackbar open={openToast} handleClose={() => setOpenToast(false)} variant={toastData.variant} message={toastData.message} />
            <CoachesDialog open={coachesDialogOpen} onHide={() => setCoachesDialogOpen(false)} onSubmit={(id, name) => {
                //props.history.push("/transactions/payouts/"+id)
                props.history.push({
                    pathname: "/transactions/payouts/" + id,
                    state: name,
                })
            }} />
        </div>
    )
}