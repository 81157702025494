import React, { useState, useEffect } from "react";
import RequestTable from "../../components/Table/RequestTable";
import {
    Avatar,
    Tooltip
} from '@material-ui/core';
import DangerButton from '../../components/Buttons/DangerButton';
import RequestDialog from '../../components/Dialogs/RequestDialog';
import { AlertSnackbar } from '../../components/Toasts/toasts';
//Services
import { getAllCoaches, updateCoachStatus } from '../../services/coachService'
import Loader from "../../components/Loader/Loader.js";
// Styles
import componentStyles from "../../assets/theme/components/page-header.js";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(componentStyles);


function CoachLists(props) {
    const classes = useStyles()
    const [coaches, setCoaches] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [actionData, setActionData] = useState({})
    const [openToast, setOpenToast] = useState(false)
    const [toastData, setToastData] = useState({
        message: "",
        variant: ""
    })
    const fetchCoaches = async () => {
        setIsLoading(true)
        const response = await getAllCoaches()
        const { success, data } = response
        if (success === true) {
            setCoaches(data)
        } else {
            setOpenToast(true)
            setToastData({
                message: "Cannot fetch Coaches.",
                variant: "error"
            })
        }
        setIsLoading(false)
    }
    const columns = [
        {
            title: "Name", sort: true, field: "name",
            render: (rowData) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", alignContent: "center" }}>
                            {rowData?.dp ? <Avatar src={rowData?.dp} className="p-mr-2" size="xlarge" shape="circle" /> :
                                <Avatar icon="pi pi-user" className="p-mr-2" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} shape="circle" />}
                        </div>
                        <Tooltip title={rowData?.name} >
                            <p style={{ margin: " 0 0 0 .65rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", }}>
                                {rowData?.name}
                            </p>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            title: "Email", field: "email",
            render: (rowData) => {
                return (
                    <Tooltip title={rowData?.email} >
                        <p style={{ margin: " 0 0 0 .65rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", }}>
                            {rowData?.email}
                        </p>
                    </Tooltip>
                )
            }
        },
        { title: "Category", field: "coachData.category" },
        {
            title: "Coach Status",
            render: (rowData) => {
                return (
                    <DangerButton fontSize="medium" label={'Disable'} onClick={() => {
                        setOpenDialog(true)
                        setActionData({
                            user: {
                                name: rowData?.name
                            },
                            action: "Disable",
                            ...rowData
                        })
                    }} />
                )
            }
        },
    ]
    const onAction = async (data) => {
        const updateResponse = await updateCoachStatus(
            {
                id: data?.id,
                email: actionData?.email,
                isCoach: false
            })
        if (updateResponse.message === "Sucsess") {
            setIsLoading(false)
            fetchCoaches()
            setOpenToast(true)
            setToastData({
                message: `Coach is disabled successfully`,
                variant: "success"
            })
        } else {
            setIsLoading(false)
            setOpenToast(true)
            setToastData({
                message: `Coach cannot be disabled`,
                variant: "error"
            })
        }
        setActionData({})
    }
    useEffect(() => {
        fetchCoaches()
    }, [])
    return (
        <div>
            <div className={classes.header} />
            <RequestTable columns={columns} data={coaches} expandable type={"coaches"} />
            <RequestDialog open={openDialog} actionData={actionData} onHide={() => { setOpenDialog(false) }} onConfirm={(data) => {
                onAction(data)
            }} />
            <Loader open={isLoading} />
            <AlertSnackbar open={openToast} handleClose={() => setOpenToast(false)} variant={toastData.variant} message={toastData.message} />
        </div>
    )
}

export default CoachLists