import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from "@material-ui/core/styles";

import theme from "./assets/theme/theme.js";
import SimpleReactLightbox from 'simple-react-lightbox'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/primereact/resources/themes/mdc-light-indigo/theme.css';
import '../node_modules/primereact/resources/primereact.min.css';
import '../node_modules/primeicons/primeicons.css'
import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import CssBaseline from "@material-ui/core/CssBaseline";

import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from '../src/store/store.js'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <SimpleReactLightbox>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />

        </PersistGate>
      </Provider>
    </SimpleReactLightbox>
  </ThemeProvider>
  ,
  document.querySelector("#root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

