import axios from "axios";
import { config } from '../utils/urls'

/**
 * @description Destructure the url and adding complaint path
 */
const url = config.url.JURNI_API_URL + "/complaint"

export const getComplaints = async (params) => {
    try {
        const response = await axios.post(url + "/get", params)
        const { data } = response
        return data
    } catch (error) {
        return {
            success:false
        }
    }
    
}
export const updateComplaints = async (complaintData) => {
    try {
        const response = await axios.post(url + "/update", complaintData)
        const { data } = response
        return data
    } catch (error) {
        return {
            success:false
        }
    }
}
export const newComplaints = async (complaintData) => {
    try {
        const response = await axios.post(url + "/new", complaintData)
        const { success, data } = response.data
        if (success === true) {
            const update = await updateComplaints({
                id: data?._id,
                updatedBy: data?.flaggedBy,
                status: "APPROVED",
                adminComment: data?.adminComment
            })
            return update
        }
    } catch (error) {
        return {
            success: false
        }
    }
}