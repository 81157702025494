import React, { useState, useEffect } from "react";
import componentStyles from "../../assets/theme/components/page-header.js";
import { makeStyles } from "@material-ui/core/styles";
import { StoriesTable } from "../../components/Table/StoriesTable.js";
import { InputSwitch } from 'primereact/inputswitch';

//components import 
import IconButton from "../../components/Buttons/IconButton.js";
import CategoryAdd from "../../components/Dialogs/Category/CategoryAdd.js";
import ToolbarTab from '../../components/Toolbar/Toolbar';
import Loader from '../../components/Loader/Loader';
import { AlertSnackbar } from '../../components/Toasts/toasts';
//icons import
import EditIcon from '@mui/icons-material//Edit';
import DeleteIcon from '@mui/icons-material//Delete';
//services
import { addCategory, getAllCategory, updateCategory } from "../../services/categoryService.js";

//styles import
const useStyles = makeStyles(componentStyles);

function Category(props) {

    const classes = useStyles()
    const [categories, setCategories] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [addModal, setAddModal] = useState(false)
    const [editData, setEditData] = useState({})
    const [openToast, setOpenToast] = useState(false)
    const [toastData, setToastData] = useState({
        message: "",
        variant: ""
    })
    const fetchCategories = async () => {
        setIsLoading(true)
        const response = await getAllCategory()
        const { success, categories } = response
        if (success) {
            setCategories(categories)
        } else {
            setOpenToast(true)
            setToastData({
                message: "Cannot fetch Category.",
                variant: "error"
            })
        }
        setIsLoading(false)
    }

    const columns = [
        { title: "Name", field: 'name' },
        {
            title: "Status", field: '',
            render: (rowData) => {
                return (
                    <div >
                        <InputSwitch onChange={(e) => {
                            onEdit({
                                isActive: e.value,
                                id: rowData?._id
                            })
                        }} checked={rowData?.isActive} />
                    </div>
                )
            }
        },
        {
            title: "Actions", field: '',
            render: (rowData) => {
                return (
                    <div >
                        <EditIcon onClick={() => {
                            setEditData(rowData)
                            setAddModal(true)
                        }} style={{ cursor: "pointer" }} /> &nbsp;
                        <DeleteIcon onClick={() => {
                            onEdit({
                                id: rowData?._id,
                                isDeleted: true
                            }, "deleted")
                        }} style={{ cursor: "pointer" }} />
                    </div>
                )
            }
        },
    ]
    const onSave = async (data) => {
        const response = await addCategory(data)
        const { success } = response
        if (success) {
            setToastData({
                message: 'Category added successfully',
                variant: 'success'
            })
            setOpenToast(true)
            fetchCategories()
        } else {
            setToastData({
                message: 'Category cannot be added',
                variant: 'error'
            })
            setOpenToast(true)
        }
        setAddModal(false)
    }
    const onEdit = async (data, action) => {
        const response = await updateCategory(data)
        const { success } = response
        if (success) {
            setToastData({
                message: `Category ${action ? action : "updated"} successfully`,
                variant: 'success'
            })
            setOpenToast(true)
            fetchCategories()
        } else {
            setToastData({
                message: `Category cannot be ${action ? action : "updated"}`,
                variant: 'error'
            })
            setOpenToast(true)
        }
        setAddModal(false)
        setEditData({})
    }
    useEffect(() => {
        setAddModal(false)
        fetchCategories()
    }, [])
    return (
        <div>
            <div className={classes.header} />
            <ToolbarTab leftContents={<IconButton icon="pi pi-plus" label="Add" styles={{ backgroundColor: "rgb(94,113,228)", color: "#fff" }} className={"toolbarButton"} onClick={() => { setAddModal(true) }} />} />
            <StoriesTable data={categories} columns={columns} />
            <CategoryAdd open={addModal} onHide={() => {
                setAddModal(false)
                setEditData({})
            }} category={editData} onSave={data => onSave(data)} onEdit={data => onEdit(data, "updated")} />
            <Loader open={isLoading} />
            <AlertSnackbar open={openToast} handleClose={() => setOpenToast(false)} variant={toastData.variant} message={toastData.message} />
        </div>
    )
}
export default Category