import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import FilledInput from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
//import FormControlLabel from "@material-ui/core/FormControlLabel";
//import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons components
import Email from "@material-ui/icons/Email";
import Lock from "@material-ui/icons/Lock";
import componentStyles from "../../assets/theme/views/auth/login.js";

// core components
import AuthHeader from '../../components/Headers/AuthHeader.js'
import Container from "@material-ui/core/Container";
import AuthFooter from '../../components/Footers/AuthFooter';

//import AuthNavbar from '../../components/Navbars/AuthNavbar'
import { login } from '../../services/authService.js'
import Loader from "../../components/Loader/Loader.js";

import {setProfile} from '../../store/action/profile'
import { connect } from "react-redux";
const useStyles = makeStyles(componentStyles);

export function Login(props) {
    const { setProfile, history } = props
    const classes = useStyles();
    const theme = useTheme();
    const [userData, setUserData] = useState({
        username: "",
        password: ""
    })

    const [credentialError, setCredentialError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const submitHandler = async (e) => {
        setIsLoading(true)
        setCredentialError(false)
        e.preventDefault()
        const loginResponse = await login(userData)
        if (loginResponse.isAuth === true) {
            setProfile(loginResponse)
            history.replace("/dashboard")
        } else {
            setCredentialError(true)
            setIsLoading(false)
        }
    }
    useEffect(() => {
        setCredentialError(false)
    }, [])
    return (
        <>
            <div className="main-content" >
                <AuthHeader />
                <Container
                    component={Box}
                    maxWidth="xl"
                    marginTop="-8rem"
                    paddingBottom="3rem"
                    position="relative"
                    zIndex="101"
                >
                    <Box component={Grid} container justifyContent="center">
                        <Grid item xs={12} lg={5} md={7}>
                            <Card classes={{ root: classes.cardRoot }}>
                                <CardHeader
                                    className={classes.cardHeader}
                                    subheader={"Sign in"}
                                ></CardHeader>
                                <form onSubmit={submitHandler}>
                                    <CardContent classes={{ root: classes.cardContent }}>
                                        <Box
                                            color={theme.palette.gray[600]}
                                            textAlign="center"
                                            marginBottom="1rem"
                                            marginTop=".5rem"
                                            fontSize="1rem"
                                        >
                                        </Box>
                                        <FormControl
                                            variant="filled"
                                            component={Box}
                                            width="100%"
                                            marginBottom="1rem!important"
                                        >
                                            <FilledInput
                                                onChange={(e) => {
                                                    setUserData({
                                                        ...userData,
                                                        username: e.target.value
                                                    })
                                                }}
                                                required
                                                autoComplete="off"
                                                type="email"
                                                placeholder="Email"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Email />
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <FormControl
                                            variant="filled"
                                            component={Box}
                                            width="100%"
                                            marginBottom="1rem!important"
                                        >
                                            <FilledInput
                                                onChange={(e) => {
                                                    setUserData({
                                                        ...userData,
                                                        password: e.target.value
                                                    })
                                                }}
                                                required
                                                autoComplete="off"
                                                type="password"
                                                placeholder="Password"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <Lock />
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        {credentialError && <p style={{ textAlign: "center", color: "red" }}>
                                            Invalid credentials
                                        </p>}
                                        <Box textAlign="center" marginTop="1.5rem" marginBottom="1.5rem">
                                            <Button type="submit" color="primary" variant="contained">
                                                Sign in
                                            </Button>
                                        </Box>
                                    </CardContent>
                                </form>
                            </Card>
                            <Grid container component={Box} marginTop="1rem">
                                {/* <Grid item xs={6} component={Box} textAlign="left">
                                    <a
                                        href="#admui"
                                        onClick={(e) => e.preventDefault()}
                                        className={classes.footerLinks}
                                    >
                                        Forgot password
                                    </a>
                                </Grid> */}
                                {/* <Grid item xs={6} component={Box} textAlign="right">
                                    <a
                                        href="#admui"
                                        onClick={(e) => e.preventDefault()}
                                        className={classes.footerLinks}
                                    >
                                        Create new account
                                    </a>
                                </Grid> */}
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </div>
            <Loader open={isLoading} />
            <AuthFooter />
        </>
    );
}


const mapDispatchToProps = dispatch => ({  
    setProfile: (data) => dispatch(setProfile(data))
});
export default connect(null, mapDispatchToProps)(Login);
