/**
 * @ desc imports on pages
*/

import Dashboard from "./screens/dashboard/dashboard";
import Requests from "./screens/Requests/requests";
import Posts from "./screens/postScreen/posts";
import Users from "./screens/Complaints/Users/users";
import News from "./screens/Complaints/News/news";
import CoachLists from "./screens/Coaches/coaches";
import Category from "./screens/Category/category";
import Transactions from "./screens/Transactions/transaction";
import Payouts from './screens/Transactions/Payouts/payouts'
/**
 * @desc imports of icons
 */
//import ExitToApp from '@material-ui/icons/ExitToApp';
import Image from '@material-ui/icons/Image';
import Person from "@material-ui/icons/Person";
import Tv from "@material-ui/icons/Tv";
import Security from '@material-ui/icons/Security';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
var routes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: Tv,
        iconColor: "PrimaryLight",
        component: Dashboard,
        layout: "main",
    },
    {
        path: "/requests",
        name: "Requests",
        icon: Person,
        iconColor: "WarningLight",
        component: Requests,
        layout: "main",
    },
    {
        path: "/coaches",
        name: "Coaches",
        icon: AssignmentIndIcon,
        iconColor: "Primary",
        component: CoachLists,
        layout: "Parent",
    },
    {
        path: "/coaches",
        name: "All Coaches",
        // icon: WidgetsIcon,
        // iconColor: "ErrorLight",
        component: Category,
        layout: "Child",
    },
    {
        path: "/categories",
        name: "Categories",
        // icon: WidgetsIcon,
        // iconColor: "ErrorLight",
        component: Category,
        layout: "Child",
    },
    {
        path: "/posts",
        name: "Posts",
        icon: Image,
        iconColor: "Info",
        component: Posts,
        //layout: "main",
    },

    {
        path: "complaints",
        name: "Complaints",
        icon: Security,
        iconColor: "Warning",
        //component: ,
        layout: "Parent",
    },
    {
        path: "/complaints/news",
        name: "News",
        //icon: Security,
        iconColor: "Warning",
        component: News,
        layout: "Child",
    },
    {
        path: "/complaints/users",
        name: "Users",
        //icon: ExitToApp,
        iconColor: "inline",
        component: Users,
        layout: "Child",
    },
    {
        path: "transaction",
        name: "Transactions",
        icon: AttachMoneyIcon,
        iconColor: "InfoLight",
        layout: "Parent",
    },
    {
        path: "/transaction/payments",
        name: "Payments",
        layout: "Child",
        component: Transactions,
    },
    {
        path: "/transaction/payouts",
        name: "Payouts",
        layout: "Child",
        component: Payouts,
    },
]

export default routes;