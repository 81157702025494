import React, { useState, useEffect } from 'react'
import Loader from '../../components/Loader/Loader';
import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "../../assets/theme/components/page-header.js";
//import PostCard from '../../components/Cards/PostsCard';
import { getAllPosts } from '../../services/postSerivce'
import { newComplaints } from '../../services/complaintService';
import { AlertSnackbar } from '../../components/Toasts/toasts.js'
import PostTable from '../../components/Table/PostTable';
import DangerButton from '../../components/Buttons/DangerButton';
import { SRLWrapper } from "simple-react-lightbox";
import BlockDialog from '../../components/Dialogs/BlockDialog';
import dateFormat from "dateformat";
import CancelIcon from '@mui/icons-material/Cancel';
import { filterByDate } from '../../utils/FilterByDate/filterByDate.js'
/**
 * @description Importing styles through material ui
 */
const useStyles = makeStyles(componentStyles);

export default function Posts(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false)
    const [blockDialog, setBlockDialog] = useState(false)
    const [posts, setPosts] = useState([])
    const [copyPosts, setCopyPosts] = useState([])
    const [actionPost, setActionPost] = useState({})
    const [openToast, setOpenToast] = useState(false)
    const [toastData, setToastData] = useState({
        message: "",
        variant: ""
    })
    const getPosts = async () => {
        setIsLoading(true)
        const getPostsResponse = await getAllPosts()
        if (getPostsResponse) {
            setPosts(getPostsResponse)
            setCopyPosts(getPostsResponse)
        } else {
            setOpenToast(true)
            setToastData({
                message: "Cannot fetch posts.",
                variant: "error"
            })
        }
        setIsLoading(false)
    }
    const columns = [
        {
            title: "Post",
            render: (rowData) => {
                if (rowData?.dataType === 'video') {
                    return (
                        <video height={"150px"} width={"200px"} controls preload={'metadata'} controlsList={"nodownload"} >
                            <source src={rowData?.data} ></source>
                        </video>
                    )
                } else {
                    return (
                        <img style={{ cursor: "zoom-in" }} src={rowData?.data} alt="Post" height={"100px"} />
                    )
                }
            },
        },
        {
            title: "Posted by", field: "user.name",
            render: (rowData) => {
                return (
                    <p style={{ margin: "0", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", }}>
                        {rowData?.user?.name}
                    </p>
                )
            }
        },
        {
            title: "Posted on", field: "createdAt",
            render: (rowData) => {
                return (<div>
                    <p style={{ margin: "0px" }}>{dateFormat(rowData.createdAt, "mmm dS, yyyy ")}
                        <p style={{ color: "#999999", margin: "0px" }}>
                            {dateFormat(rowData.createdAt, "shortTime")}
                        </p>
                    </p>
                </div>)
            }
        },
        {
            title: "Actions",
            render: (rowData) => {
                if (rowData?.isActive) {
                    return (
                        <p>
                            <DangerButton label="Block" onClick={() => {
                                setBlockDialog(true)
                                setActionPost(rowData)
                            }} />
                        </p>
                    )
                } else {
                    return (
                        <div className="action-box">
                            <CancelIcon fontSize="medium" color={"error"} /> &nbsp; Blocked
                        </div>
                    )
                }
            }
        },
    ]
    const filterHandler = (data) => {
        setIsLoading(true)
        const filterResponse = filterByDate(data, copyPosts)
        setPosts(filterResponse)
        setIsLoading(false)
    }

    useEffect(() => {
        getPosts()
    }, [])
    const handleBlock = async (data) => {
        const blockData = {
            ...data,
            news: actionPost?._id
        }
        setIsLoading(true)
        setBlockDialog(false)
        const response = await newComplaints(blockData)
        if (response.success === true) {
            setIsLoading(false)
            getPosts()
            setOpenToast(true)
            setToastData({
                message: `Post blocked successfully`,
                variant: "success"
            })
        } else {
            setIsLoading(false)
            setOpenToast(true)
            setToastData({
                message: "Post cannot be blocked",
                variant: "error"
            })
        }
    }
    return (
        <div >
            <div className={classes.header} />
            <br />
            {/* <ToolbarTab leftContents={leftTabContents} /> */}
            {/* <PostCard url={post.url} postedBy={post.postedBy} postedOn={post.postedOn} /> */}
            <SRLWrapper>
                <PostTable data={posts} columns={columns} expandable onFilter={(data) => {
                    filterHandler(data)
                }} onClearFilter={() => setPosts(copyPosts)} />
            </SRLWrapper>
            <Loader open={isLoading} />
            <BlockDialog open={blockDialog} onHide={() => setBlockDialog(false)} onAction={(data) => handleBlock(data)} />
            <AlertSnackbar open={openToast} handleClose={() => setOpenToast(false)} variant={toastData.variant} message={toastData.message} />
        </div>
    )
}