import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
// import { Tooltip } from '@material-ui/core';
// import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';
import { InputText } from 'primereact/inputtext';
import { Paper } from '@material-ui/core';
import { Col, Row } from 'react-bootstrap';
import Avatar from '@material-ui/core/Avatar';
import './table.css'
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
export const PostTable = (props) => {
    const { data, columns, sno, expandable, onFilter, onClearFilter } = props
    const [globalFilter, setGlobalFilter] = useState("")
    const [expandedRows, setExpandedRows] = useState(null);
    const [dateRange, setDateRange] = useState([]);
    const serialNumber = (rowData, index) => {
        return (<p style={{ margin: "0", padding: "0px" }}>
            {Number(index) + 1}
        </p>)
    }

    const header = (
        <div className="table-header" style={{ display: "flex", justifyContent: "flex-end" }}>
             <Calendar id="range" value={dateRange} placeholder={"Filter by date"} onChange={(e) => {
                setDateRange(e.value)
                if(e.value[1]){
                    onFilter(e.value)
                }
                }} selectionMode="range" showIcon icon="pi pi-filter" /> &nbsp;&nbsp;&nbsp;

            {dateRange[1] && <span style={{ display: "flex", alignItems: "center" }}>
                <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined" style={{ color: "red", cursor: "pointer" }} onClick={() => {
                    setDateRange([])
                    onClearFilter()
                }} />

            </span>}
            &nbsp;&nbsp;&nbsp;
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search" />
            </span>
        </div>
    );
    const onRowExpand = (event) => {
        //toast.current.show({severity: 'info', summary: 'Product Expanded', detail: event.data.name, life: 3000});
    }
    const rowExpansionTemplate = (rowData) => {
        const { dataText, user, data, dataType } = rowData
        return (
            <div style={{ padding: "30px", backgroundColor: "#f5f5f5" }}>
                <Paper elevation={1} >
                    <Row>
                        <Col xs="12" lg="4" md="4" xl="3" style={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                            {dataType === 'video' ?
                                <video height={"150px"} width={"200px"} controls preload={'metadata'} >
                                    <source src={rowData?.data} ></source>
                                </video>
                                :
                                <img style={{ height: "auto", width: "100%", }} src={data}
                                    alt="profile" />
                            }
                        </Col>
                        <Col xs="12" lg="8" md="8" xl="9" style={{ display: "flex", justifyContent: "space-around", flexDirection: "column", padding: "1rem" }}>
                            <div>
                                <br />
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <p style={{ fontWeight: 600, display: "flex", alignItems: "center", margin: "0" }}> Posted by : </p> &nbsp;
                                    <p style={{ margin: " 0 .65rem 0 0 ", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", }}>
                                        {user?.name}
                                    </p>
                                    <div style={{ display: "flex", alignContent: "center" }}>
                                        {user?.dp ? <Avatar src={user?.dp} className="p-mr-2" size="xlarge" shape="circle" /> :
                                            <Avatar icon="pi pi-user" className="p-mr-2" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} shape="circle" />}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", alignContent: "start", marginTop: "1rem" }}>
                                <div style={{ display: "flex" }}>
                                    <p style={{ fontWeight: 600 }}>Caption:</p> &nbsp;
                                    <p>{dataText}</p>
                                </div>
                                {/* <div style={{ display: "flex" }}>
                                    <p style={{ fontWeight: 600 }}>Views:</p> &nbsp;
                                    <p>20</p>
                                </div>*/}
                            </div>
                        </Col>
                    </Row>
                </Paper>
            </div>
        );
    }
    const onRowCollapse = (event) => {
        setExpandedRows("")
        //toast.current.show({severity: 'success', summary: 'Product Collapsed', detail: event.data.name, life: 3000});
    }
    return (
        <div className="datatable-responsive-demo" >
            <DataTable value={data}
                header={header}
                editMode="row"
                removableSort
                className="p-datatable-responsive-demo "
                sortMode="single"
                // showGridlines
                paginator
                globalFilter={globalFilter}
                style={{ fontSize: "0.9rem" }}
                rows={10}
                dataKey="_id"
                expandedRows={expandedRows}
                onRowToggle={(e) => {
                    setExpandedRows(e.data)
                }}
                onRowExpand={onRowExpand} rowExpansionTemplate={rowExpansionTemplate}
                onRowCollapse={onRowCollapse}
            >
                {expandable && <Column expander headerStyle={{fontWeight:600, backgroundColor: "#E6EAED",width: "5%" }} style={{ width: "5%" }} />}
                {sno && <Column body={(rowData, tableProps) => serialNumber(rowData, tableProps.rowIndex)} headerStyle={{fontSize:"28px !important", textAlign:"center", fontWeight:600, backgroundColor: "#E6EAED"}} header={"S NO"} style={{ width: "5%", textAlign: "center" }}  ></Column>}
                {columns && columns.map((column) => {
                    return (
                        <Column style={{
                            ...column.style,
                            textAlign: "center",
                            fontWeight:500
                        }
                        }
                            key={column._id}
                            field={column.field} header={column.title} headerStyle={{fontSize:"26px !important", textAlign:"center", fontWeight:600, backgroundColor: "#E6EAED"}}
                            body={column.render ? column.render : false}
                            sortable={column.sort ? column.sort : false}
                        />
                    )
                })}

                {/* {isAdmin() && (deleteAction || edit) && <Column header="Actions" body={(e) => customAction(e)}
                    style={{ width: "8%" }}
                ></Column>} */}
            </DataTable>

        </div>

    )
}
export default PostTable