import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { Chip } from 'primereact/chip';

//components
import RequestTable from "../../components/Table/RequestTable";
import Loader from '../../components/Loader/Loader';
import { AlertSnackbar } from '../../components/Toasts/toasts';
import ToolbarTab from '../../components/Toolbar/Toolbar';

//services
import { getAllTransactions } from '../../services/transactionService'

//styles
import { makeStyles } from "@material-ui/core/styles";
import componentStyles from "../../assets/theme/components/page-header.js";

//utils
import { filterByStatus } from '../../utils/FilterTransactions';
import { firebaseAnalytics } from "../../analytics/firebase";

//icons 
import { FaCcPaypal } from "react-icons/fa";
import { FaCcStripe } from "react-icons/fa";

const useStyles = makeStyles(componentStyles);
export default function Transactions(props) {
    const [transactions, setTransactions] = useState([])
    const [copyTransactions, setCopyTransactions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [openToast, setOpenToast] = useState(false)
    const [toastData, setToastData] = useState({
        message: "",
        variant: ""
    })
    const [filter, setFilter] = useState("All")
    const classes = useStyles()
    const fetchTransactions = async () => {
        setIsLoading(true)
        const response = await getAllTransactions()
        const { success, transactions } = response
        if (success === true) {
            console.log(transactions)
            setTransactions(transactions)
            setCopyTransactions(transactions)
        } else {
            setOpenToast(true)
            setToastData({
                message: "Cannot fetch transactions.",
                variant: "error"
            })
        }
        setIsLoading(false)
    }
    const columns = [
        { title: "Name", field: "paidBy.name" },
        { title: "Coach Name", field: "paidTo.name" },
        {
            title: "Amount", field: "totalAmount",
            // render: (rowData) => {
            //     return (
            //         <p style={{ margin: "0px" }}>
            //             {rowData?.totalAmount} $
            //         </p>
            //     )
            // }
        },
        {title:"GatewayCharges", field: "gatewayCharges"},
        {title:"Currency", field: "currency"},
        {
            title: "Payment Status", field: "status", render: (rowData) => {
                if (rowData?.status === "PENDING") {
                    return (<p style={{
                        padding: "2px", margin: "0 1rem",
                        background: "#fff3c9", borderRadius: "10px", color: "#d68b00"
                    }} >
                        Pending
                    </p>)
                }
                if (rowData?.status === "DONE") {
                    return (<p style={{
                        padding: "2px", margin: "0 1rem",
                        background: "#b2fa8e", borderRadius: "10px", color: "#267300"
                    }} >
                        Done
                    </p>)
                }
            }
        },
       // { title: "Type", field: "type" },
        {
            title: "Gateway", field: "gateway",
            render: (rowData) => {
                if (rowData?.gateway === "STRIPE") {
                    return (
                        <FaCcStripe size={35} />
                    )
                } else if (rowData?.gateway === "PAYPAL") {
                    return (
                        <FaCcPaypal size={35} />
                    )
                }
            }
        },
        {
            title: "Paid on",
            field: "createdAt",
            sort:true,
            render: (rowData) => {
                return (
                    <div>
                        <p style={{ margin: "0px" }}>{dateFormat(rowData.createdAt, "mmm dS, yyyy ")}
                            <p style={{ color: "#999999", margin: "0px" }}>
                                {dateFormat(rowData.createdAt, "shortTime")}
                            </p>
                        </p>
                    </div>
                );
            },
        },
    ]
    const filterHandlerType = (status) => {
        if (status === "All") {
            setFilter("All")
            setCopyTransactions(transactions)
        } else {
            setFilter(status)
            const response = filterByStatus(status, transactions)
            setCopyTransactions(response)
        }
    }

    const leftTabContents = (
        <React.Fragment>
            <div onClick={() => {
                filterHandlerType("All")
            }}>
                <Chip label="All" style={(filter === "All") ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className={"toolbarButton"} />
            </div>
            <div onClick={() => {
                filterHandlerType("PENDING")
            }}>
                <Chip label="Pending" style={(filter === "PENDING") ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className="toolbarButton" />
            </div>
            <div onClick={() => {
                filterHandlerType("DONE")
            }}><Chip label="Done" style={(filter === "DONE") ? { backgroundColor: "rgb(94,113,228)", color: "#fff" } : {}} className="toolbarButton" />
            </div>
        </React.Fragment>
    )

    useEffect(() => {
        fetchTransactions()
        firebaseAnalytics.logEvent("paymentsPage_visited");
    }, [])
    return (
        <div>
            <div className={classes.header} />
            <ToolbarTab leftContents={leftTabContents} />
            <RequestTable data={copyTransactions} columns={columns} />
            <Loader open={isLoading} />
            <AlertSnackbar open={openToast} handleClose={() => setOpenToast(false)} variant={toastData.variant} message={toastData.message} />
        </div>
    )
}