import React from "react";
import './button.css'
import { Button } from 'primereact/button';

export default function DangerButton (props) {
    const {label, onClick} = props
    const onClickAction = (e) =>{
        onClick(e)
    }
    return(
        <Button onClick={(e)=>onClickAction(e)} style={{backgroundColor:"#FF6D6C"}} >{label}</Button>
    )
}